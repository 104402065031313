/* eslint-disable */
<template>
  <!-- eslint-disable max-len -->
  <fragment>
    <loader v-if="loading === true" object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" name="circular"></loader>
    <section class="content-wrapper">
      <nav class="breadcrumb-show">
        <div class="container">
          <a href="javascript:(0)" class="breadcrumb-show__item" aria-current="page">Cart</a>
          <p class="breadcrumb-show__description">
            Print your desired look on ready-made shirts with various sizes and colors.
          </p>
        </div>
      </nav>
      <nav aria-label="breadcrumb " class="d-none d-md-block">
        <div class="container">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item"><a href="#">Shop</a></li>
            <li class="breadcrumb-item"><a href="#">Cart</a></li>
            <li class="breadcrumb-item active" aria-current="page">Checkout</li>
          </ol>
        </div>
      </nav>
      <div class="cart cart-checkout">
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <div class="cart-checkout__content">
                <h3 class="cart-checkout__content--title">Checkout</h3>
                <div class="cart-checkout__content--address">
                  <div class="info-ship mb-4">
                    <div class="title">Shipping information</div>
                    <label class="checkbox-design" v-for="(ship, index) in shippingInformations" :key="index">
                      <input type="radio" v-model="shippingId" :checked="ship.id == shippingId" :value="ship.id"/>
                      <span class="checkbox-square"></span>
                      {{ ship.email }} <br />
                      {{ship.streetAddress }}, {{ship.other}}, {{ship.city}}, {{ship.country}}.<br />{{ ship.phone }}
                    </label>
                  </div>
                  <div class="info-ship mb-4">
                    <label class="checkbox-design">
                      <input type="radio" v-model="shippingId" value="new"/>
                      <span class="checkbox-square"></span>
                      <span class="title">Add new shipping address</span>
                    </label>
                    <div class="info-ship__content">
                      <form class="row g-3 needs-validation" novalidate>
                        <div class="col-12">
                          <input
                            type="text"
                            class="form-control"
                            id="validationCustom01"
                            value="Mark"
                            v-model="fullName"
                            placeholder="Full Name"
                            required
                          />
                          <div class="invalid-feedback invalid-name show">
                            Please provide your full name.
                          </div>
                        </div>
                        <div class="col-12">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Street address"
                            id="validationCustom03"
                            v-model="streetAddress"
                            required
                          />
                          <div class="invalid-feedback invalid-street">
                            Please provide a valid city.
                          </div>
                        </div>
                        <div class="col-12">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Apt / Suite/ Other (optional)"
                            id="validationCustom03"
                            v-model="other"
                            required
                          />
                          <div class="invalid-feedback">
                            Please provide a valid city.
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="row">
                            <div class="col-md-6">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="City"
                                v-model="city"
                                id="validationCustom03"
                                required
                              />
                              <div class="invalid-feedback invalid-city">
                                Please provide a valid city.
                              </div>
                            </div>
                            <div class="col-md-6 mt-md-0 mt-3">
                              <!-- <select class="form-control select-custom">
                                <option selected="selected">State</option>
                                <option>white</option>
                                <option>purple</option>
                              </select> -->
                              <input
                                type="text"
                                class="form-control"
                                placeholder="State"
                                v-model="state"
                                id="validationCustom03"
                                required
                              />
                              <div class="invalid-feedback">
                                Please provide a valid state.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="row">
                            <div class="col-md-6">
                              <input
                                type="text"
                                class="form-control"
                                id="validationCustom03"
                                v-model="zipCode"
                                placeholder="Zipcode"
                                required
                              />
                              <div class="invalid-feedback invalid-zipcode">
                                Please provide a valid city.
                              </div>
                            </div>
                            <div class="col-md-6 mt-md-0 mt-3">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Country"
                                v-model="country"
                                id="validationCustom03"
                                required
                              />
                              <div class="invalid-feedback invalid-country">
                                Please select a valid country.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <div class="row">
                            <div class="col-md-6">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Email"
                                v-model="email"
                                id="validationCustom03"
                                required
                              />
                              <div class="invalid-feedback invalid-email">
                                Please provide a valid city.
                              </div>
                            </div>
                            <div class="col-md-6 mt-md-0 mt-3">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Phone"
                                v-model="phone"
                                id="validationCustom03"
                                required
                              />
                              <div class="invalid-feedback invalid-phone">
                                Please provide a valid city.
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                      <!-- <label class="checkbox-design save-info">
                        <input type="radio" name="programming" />
                        <span class="checkbox-square"></span>
                        <span class="title">Save this information for next time</span>
                      </label> -->
                    </div>
                  </div>
                </div>
                <hr class="py-3 py-md-4" />
                <!-- <h3 class="cart-checkout__content--title small">Your order</h3>
                <div class="cart__items">
                  <img src="../assets/images/shop-img.png" class="cart__items--image" alt="" />
                  <div class="cart__items--content w-100">
                    <div class="title d-flex justify-content-between">
                      <h3 class="mb-0">Infinity Run Flyknit 3</h3>
                      <button class="btn p-0">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="4"
                            y="3"
                            width="25"
                            height="1.5"
                            rx="0.75"
                            transform="rotate(45 4 3)"
                            fill="#656782"
                          />
                          <rect
                            x="2.93945"
                            y="20.6777"
                            width="25"
                            height="1.5"
                            rx="0.75"
                            transform="rotate(-45 2.93945 20.6777)"
                            fill="#656782"
                          />
                        </svg>
                      </button>
                    </div>
                    <p class="list-item">T-Shirt Men</p>
                    <p class="list-item">Color: Black</p>
                    <p class="list-item">Size: XXL</p>
                    <div class="action d-flex justify-content-between align-items-center">
                      <div class="quality__control">
                        <button class="btn p-0">
                          <img src="../assets/images/minus-control.png" alt="" />
                        </button>
                        <input type="text" value="1" />
                        <button class="btn p-0">
                          <img src="../assets/images/plus-control.png" alt="" />
                        </button>
                      </div>
                      <p class="action__price mb-0">$40</p>
                    </div>
                  </div>
                </div>
                <div class="cart__items">
                  <img src="../assets/images/shop-img.png" class="cart__items--image" alt="" />
                  <div class="cart__items--content w-100">
                    <div class="title d-flex justify-content-between">
                      <h3 class="mb-0">Infinity Run Flyknit 3</h3>
                      <button class="btn p-0">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="4"
                            y="3"
                            width="25"
                            height="1.5"
                            rx="0.75"
                            transform="rotate(45 4 3)"
                            fill="#656782"
                          />
                          <rect
                            x="2.93945"
                            y="20.6777"
                            width="25"
                            height="1.5"
                            rx="0.75"
                            transform="rotate(-45 2.93945 20.6777)"
                            fill="#656782"
                          />
                        </svg>
                      </button>
                    </div>
                    <p class="list-item">T-Shirt Men</p>
                    <p class="list-item">Color: Black</p>
                    <p class="list-item">Size: XXL</p>
                    <div class="action d-flex justify-content-between align-items-center">
                      <div class="quality__control">
                        <button class="btn p-0">
                          <img src="../assets/images/minus-control.png" alt="" />
                        </button>
                        <input type="text" value="1" />
                        <button class="btn p-0">
                          <img src="../assets/images/plus-control.png" alt="" />
                        </button>
                      </div>
                      <p class="action__price mb-0">$40</p>
                    </div>
                  </div>
                </div>
                <div class="cart__items">
                  <img src="../assets/images/shop-img.png" class="cart__items--image" alt="" />
                  <div class="cart__items--content w-100">
                    <div class="title d-flex justify-content-between">
                      <h3 class="mb-0">Infinity Run Flyknit 3</h3>
                      <button class="btn p-0">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="4"
                            y="3"
                            width="25"
                            height="1.5"
                            rx="0.75"
                            transform="rotate(45 4 3)"
                            fill="#656782"
                          />
                          <rect
                            x="2.93945"
                            y="20.6777"
                            width="25"
                            height="1.5"
                            rx="0.75"
                            transform="rotate(-45 2.93945 20.6777)"
                            fill="#656782"
                          />
                        </svg>
                      </button>
                    </div>
                    <p class="list-item">T-Shirt Men</p>
                    <p class="list-item">Color: Black</p>
                    <p class="list-item">Size: XXL</p>
                    <div class="action d-flex justify-content-between align-items-center">
                      <div class="quality__control">
                        <button class="btn p-0">
                          <img src="../assets/images/minus-control.png" alt="" />
                        </button>
                        <input type="text" value="1" />
                        <button class="btn p-0">
                          <img src="../assets/images/plus-control.png" alt="" />
                        </button>
                      </div>
                      <p class="action__price mb-0">$40</p>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="col-md-4">
              <div class="cart__price mt-5 mt-md-0 ms-md-3">
                <h3 class="cart__price--title">Summary</h3>
                <div class="cart__price--content">
                  <div class="item-price">
                    <label for="">Subtotal</label>
                    <p class="mb-0">${{subTotal}}</p>
                  </div>
                  <div class="item-price">
                    <label for="">Estimated delivery & Handling</label>
                    <p class="mb-0">${{fee}}</p>
                  </div>
                  <div class="item-price">
                    <label for="">Print fee</label>
                    <p class="mb-0">${{printFee}}</p>
                  </div>
                </div>
                <hr />
                <div class="cart__price--total">
                  <label for="">Total</label>
                  <p class="mb-0">${{ subTotal + fee + printFee}}</p>
                </div>
                <div class="row mt-3">
                  <div class="col-xl-6">
                    <button class="btn btn-primary-2 w-100 mb-2" @click="checkoutCart">Checkout</button>
                  </div>
                  <div class="col-xl-6">
                    <router-link class="btn btn-primary w-100 mb-2" :to="{ name: 'Cart'}">Back to shop</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </fragment>
</template>

<script>
/* eslint-disable */
import { mapActions } from 'vuex';
import { FEE } from "../constants/fee";

export default {
  data() {
    return {
      subTotal: this.$route.params.subTotal,
      fee: this.$route.params.fee,
      printFee: this.$route.params.printFee,
      total: this.$route.params.subTotal + this.$route.params.fee + this.$route.params.printFee,
      cartId: this.$route.params.cartId,
      fullName: '',
      streetAddress: '',
      other: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
      email: '',
      phone: '',
      shippingId: null,
      loading: false,
      shippingInformations: [],
    };
  },
  async mounted() {
    this.shippingInformations = await this.getShippingInformations();
    if (this.shippingInformations) { this.shippingId = this.shippingInformations[0].id; }
  },
  computed: {},
  methods: {
    ...mapActions({
      checkout: "cart/checkout",
      getShippingInformations: 'cart/getShippingInformations',
    }),
    async checkoutCart() {
      const shippingInfo = {
        fullName: this.fullName,
        streetAddress: this.streetAddress,
        other: this.other,
        city: this.city,
        state: this.state,
        zipCode: this.zipCode,
        country: this.country,
        email: this.email,
        phone: this.phone,
      }

      let params = {
        title: 'Payment tshirt',
        amount: this.total,
        description: 'This is a payment tshirt',
        currency: 'USD',
        shippingId: this.shippingId,
        cartId: this.cartId
      };
      if (this.shippingId == 'new') {
        $('.invalid-feedback').css('display','none');
        if (this.fullName === '') {
          $('.invalid-name').css('display','block');
          return;
        } else if (this.streetAddress === 'streetAddress_is_required') {
          $('.invalid-street').css('display','block');
          return;
        } else if (this.city === '') {
          $('.invalid-city').css('display','block');
          return;
        } else if (this.zipCode === '') {
          $('.invalid-zipcode').css('display','block');
          return;
        } else if (this.country === '') {
          $('.invalid-country').css('display','block');
          return;
        } else if (this.email === '') {
          $('.invalid-email').css('display','block');
          return;
        } else if (this.phone === '') {
          $('.invalid-phone').css('display','block');
          return;
        }
        params.shippingInfo = shippingInfo;
      }      
      this.loading = true;
      const url = await this.checkout(params).finally(() => this.loading = false)
      window.location.assign(url);
    },
  }
}
</script>
