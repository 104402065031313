<template>
      <div
      class="modal save-design fade"
      :class="{isShowModal: 'show'}"
      id="modalThankYou"
      tabindex="-1"
      aria-labelledby="modalThankYouLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-mb-centered">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="save-design__title mb-3">Thank you!</div>
            <div class="save-design__confirm active">
              <div class="py-3 px-2">
                Your order has been received and will be delivered to you as soon as possible.
              </div>
              <div class="code-order pb-5">
              <p class="">Number order</p>
              <p class="code-order__code-number">23F3EF432</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'ThankYouCheckout',
  props: {
    isShowModal: {
      type: Boolean,
      require: true,
      default: false,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {
    hideModal() {
      $('#modalThankYou').modal('toggle');
    }
  },
};
</script>

<style lang="sass" scoped>
</style>
