/* eslint-disable */
<template>
  <!-- eslint-disable max-len -->
  <fragment>
    <section class="content-wrapper">
      <nav class="breadcrumb-show">
        <div class="container">
          <a href="javascript:(0)" class="breadcrumb-show__item" aria-current="page"> Cart</a>
          <p class="breadcrumb-show__description">
            Print your desired look on ready-made shirts with various sizes and colors.
          </p>
        </div>
      </nav>
      <nav aria-label="breadcrumb " class="d-none d-md-block">
        <div class="container">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item"><a href="#">Shop</a></li>
            <li class="breadcrumb-item"><a href="#">Cart</a></li>
            <li class="breadcrumb-item active" aria-current="page">Payment</li>
          </ol>
        </div>
      </nav>
      <div class="cart cart-checkout">
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <div class="cart-checkout__content">
                <h3 class="cart-checkout__content--title">Payment</h3>
                <p class="cart-checkout__content--description">
                  All transactions are secure and encrypted.
                </p>
                <div class="payment">
                  <div class="payment__content card">
                    <div class="payment-item">
                      <div
                        class="d-flex align-items-center justify-content-between"
                        data-bs-toggle="collapse"
                        href="#visaCollapse"
                        role="button"
                        aria-expanded="true"
                        aria-controls="visaCollapse"
                      >
                        <label class="checkbox-design">
                          <input type="radio" name="programming" />
                          <span class="checkbox-square checked"></span>
                          <span class="title">Credit Card</span>
                        </label>
                        <div class="d-flex align-items-center">
                          <img src="../assets/images/visa.png" class="ms-2" alt="" />
                          <img src="../assets/images/mastercard.png" class="ms-2" alt="" />
                          <img src="../assets/images/american-express.png" class="ms-2" alt="" />
                        </div>
                      </div>
                      <div class="payment__content--infomation collapse show" id="visaCollapse">
                        <form class="row g-3 needs-validation" novalidate>
                          <div class="col-12">
                            <input
                              type="text"
                              class="form-control"
                              id="validationCustom01"
                              value="3423 3423 3242 2342"
                              required
                            />
                            <div class="valid-feedback">Looks good!</div>
                          </div>
                          <div class="col-12">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Name on card"
                              id="validationCustom03"
                              required
                            />
                            <div class="invalid-feedback">
                              Please provide a valid city.
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="row">
                              <div class="col-md-6">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Expiration date"
                                  id="validationCustom03"
                                  required
                                />
                                <div class="invalid-feedback">
                                  Please provide a valid city.
                                </div>
                              </div>
                              <div class="col-md-6 mt-md-0 mt-3">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Expiration date"
                                  id="validationCustom03"
                                  required
                                />
                                <div class="invalid-feedback">
                                  Please provide a valid city.
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <hr />
                    <div class="payment-item">
                      <div
                        class="d-flex align-items-center justify-content-between"
                        data-bs-toggle="collapse"
                        href="#visaCollapse"
                        role="button"
                        aria-expanded="true"
                        aria-controls="visaCollapse"
                      >
                        <label class="checkbox-design">
                          <input type="radio" name="programming" />
                          <span class="checkbox-square checked"></span>
                          <span class="title">Credit Card</span>
                        </label>
                        <div class="d-flex align-items-center">
                          <img src="../assets/images/paypal.png" class="ms-2" alt="" />
                        </div>
                      </div>
                      <div class="payment__content--infomation collapse " id="visaCollapse">
                        <div class="text-center description-payment p-4">
                          After clicking “Complete order”, you will be redirected to PayPal to
                          complete your purchase securely.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="cart__price mt-5 mt-md-0 ms-md-3">
                <h3 class="cart__price--title">Summary</h3>
                <div class="cart__price--content">
                  <div class="item-price">
                    <label for="">Subtotal</label>
                    <p class="mb-0">$350</p>
                  </div>
                  <div class="item-price">
                    <label for="">Estimated delivery & Handling</label>
                    <p class="mb-0">$25</p>
                  </div>
                </div>
                <hr />
                <div class="cart__price--total">
                  <label for="">Total</label>
                  <p class="mb-0">$375</p>
                </div>
                <div class="row mt-3">
                  <div class="col-xl-6">
                    <button class="btn btn-primary-2 w-100 mb-2" data-bs-toggle="modal" data-bs-target="#modalThankYou">Checkout</button>
                  </div>
                  <div class="col-xl-6">
                    <button class="btn btn-primary w-100 mb-2">Back to shop</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <ThankYouCheckout
      :isShowModal="isShowThankYouModal"
    />
  </fragment>
</template>
<script>
import ThankYouCheckout from '@/components/modal/ThankYouCheckout.vue';

export default {
  data() {
    return {
      isShowThankYouModal: false,
    };
  },
  async mounted() {
    // this.myDesigns = await this.getDesigns();
  },
  computed: {},
  methods: {
  },
  components: {
    ThankYouCheckout,
  },
};
</script>
