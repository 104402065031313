/* eslint-disable */
<template>
  <!-- eslint-disable max-len -->
  <fragment>
    <section class="content-wrapper">
      <nav class="breadcrumb-show">
        <div class="container">
          <a href="javascript:(0)" class="breadcrumb-show__item" aria-current="page">Cart</a>
          <p class="breadcrumb-show__description">
            Print your desired look on ready-made shirts with various sizes and colors.
          </p>
        </div>
      </nav>
      <nav aria-label="breadcrumb " class="d-none d-md-block">
        <div class="container">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <!-- <li class="breadcrumb-item"><a href="#">Shop</a></li> -->
            <li class="breadcrumb-item active" aria-current="page">Cart</li>
          </ol>
        </div>
      </nav>
      <div class="cart">
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <div class="cart__items" v-for="(cart, index) in cartItems" :key="index">
                <img :src="cart.thumbnail" class="cart__items--image" alt="" />
                <div class="cart__items--content w-100">
                  <div class="title d-flex justify-content-between">
                    <h3 class="mb-0">{{ cart.productName }}</h3>
                    <button class="btn p-0" @click="deleteItemFromCart(cart.itemId)">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="4"
                          y="3"
                          width="25"
                          height="1.5"
                          rx="0.75"
                          transform="rotate(45 4 3)"
                          fill="#656782"
                        />
                        <rect
                          x="2.93945"
                          y="20.6777"
                          width="25"
                          height="1.5"
                          rx="0.75"
                          transform="rotate(-45 2.93945 20.6777)"
                          fill="#656782"
                        />
                      </svg>
                    </button>
                  </div>
                  <p class="list-item">{{ cart.productDescription }}</p>
                  <p class="list-item">Color: {{ capitalizeFirstLetter(bgColorNames['k'+ cart.color]) }}</p>
                  <p class="list-item">Size: {{ cart.size }}</p>
                  <div class="action d-flex justify-content-between align-items-center">
                    <div class="quality__control">
                      <button class="btn p-0" @click="minusQuantity(index)">
                        <img src="../assets/images/minus-control.png" alt="" />
                      </button>
                      <input type="number" v-model="cart.quantity"/>
                      <button class="btn p-0" @click="addQuantity(index)">
                        <img src="../assets/images/plus-control.png" alt="" />
                      </button>
                    </div>
                    <p class="action__price mb-0">${{ cart.price }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4" v-if="cartItems.length > 0">
              <div class="cart__price mt-5 mt-md-0 ms-md-3">
                <h3 class="cart__price--title">Summary</h3>
                <div class="cart__price--content">
                  <div class="item-price">
                    <label for="">Subtotal</label>
                    <p class="mb-0">${{ subTotal }}</p>
                  </div>
                  <div class="item-price">
                    <label for="">Estimated delivery & Handling</label>
                    <p class="mb-0">${{ fee }}</p>
                  </div>
                  <div class="item-price">
                    <label for="">Print fee</label>
                    <p class="mb-0">${{ printFee }}</p>
                  </div>
                </div>
                <hr />
                <div class="cart__price--total">
                  <label for="">Total</label>
                  <p class="mb-0">${{ total }}</p>
                </div>
                <div class="row mt-3">
                  <div class="col-xl-12">
                    <router-link class="btn btn-primary-2 w-100 mb-2" :to="{ name: 'CartCheckout', params: { subTotal: subTotal, fee: fee, printFee: printFee, cartId: cartId }}">Confirm</router-link>
                  </div>
                  <!-- <div class="col-xl-6">
                    <button class="btn btn-primary w-100 mb-2">Back to shop</button>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </fragment>
</template>
<script>
/* eslint-disable */
import { mapActions } from 'vuex';
import { FEE } from "../constants/fee";
import { BG_COLOR_NAMES } from "../constants/colors";

export default {
  data() {
    return {
      cartItems: [],
      subTotal: 0,
      fee: FEE.fee,
      total: 0,
      printFee: FEE.printFee,
      cartId: null,
      bgColorNames: BG_COLOR_NAMES
    };
  },
  async mounted() {
    this.cartItems = await this.getCartItems();
    if (this.cartItems.length > 0 ) { this.cartId = this.cartItems[0].cartId; }
    this.getSubTotal();
  },
  computed: {},
  methods: {
    ...mapActions({
      getCartItems: 'cart/getCartItems',
      deleteCart: "cart/deleteCart",
    }),
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    minusQuantity(index) {
      this.cartItems[index].quantity -= 1;
      if(this.cartItems[index].quantity < 1) { this.cartItems[index].quantity = 1; }
      this.getSubTotal();
    },
    addQuantity(index) {
      this.cartItems[index].quantity += 1;
      this.getSubTotal();
    },
    getSubTotal() {
      let subTotal = 0;
      $.each(this.cartItems, function(_, cart) {
        subTotal += cart.quantity * cart.price;
      })
      this.subTotal = subTotal;
      this.total = this.subTotal + this.fee + this.printFee
    },
    async deleteItemFromCart(itemId) {
      const params = {
        itemId: itemId
      }
      const result = await this.deleteCart(params).finally(() => this.loading = false);
      console.log(result)
      if(result.data.code == 200) { this.$router.go(); }
    }
  },
};
</script>
